@import url(https://fonts.googleapis.com/css2?family=Sora&display=swap);
* {
    /* font-family: 'nexa-rust', sans-serif;
    src: local('nexa-rust'), url('./assets/font/nexa-rust.sans-black.otf') format('opentype'); */
    font-family: 'Sora', sans-serif;
    font-size: 14px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
